import React, { useState, useEffect } from 'react';
import { UploadCloud, AlertCircle, Check, Wifi, WifiOff, Clock, HardDrive, Activity, Filter, Calendar, ChevronDown, ChevronUp, Search } from 'lucide-react';
import Papa from 'papaparse';

const MobileDeviceAnalyzer = () => {
    const [fileData, setFileData] = useState(null);
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState('Name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterStatus, setFilterStatus] = useState('all');
    const [activeTab, setActiveTab] = useState('dashboard');
    const [statsData, setStatsData] = useState(null);
    const [inactiveDevices, setInactiveDevices] = useState([]);

    // Función para cargar el archivo CSV
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setLoading(true);
        setError('');

        try {
            setFileData(file);

            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                processCSVData(content);
            };
            reader.readAsText(file);
        } catch (err) {
            setError('Error al cargar el archivo: ' + err.message);
            setLoading(false);
        }
    };

    // Procesamiento del CSV
    const processCSVData = (content) => {
        try {
            const lines = content.split('\r\n');

            // Encontrar el índice de la línea de encabezados
            const headerIndex = lines.findIndex(line => line.includes('Name\t,License'));

            if (headerIndex === -1) {
                setError('Formato de archivo no válido. No se encontraron los encabezados esperados.');
                setLoading(false);
                return;
            }

            // Procesar los encabezados
            const headers = lines[headerIndex].split('\t,').map(h => h.trim());

            // Procesar los datos
            const deviceData = [];
            for (let i = headerIndex + 1; i < lines.length; i++) {
                if (!lines[i] || lines[i].trim() === '') continue;

                const values = lines[i].split('\t,').map(v => v.trim());
                if (values.length > 1) {
                    const device = {};
                    headers.forEach((header, idx) => {
                        if (idx < values.length) {
                            device[header] = values[idx];
                        }
                    });
                    deviceData.push(device);
                }
            }

            setDevices(deviceData);
            calculateStats(deviceData);
            setLoading(false);
        } catch (err) {
            setError('Error al procesar el archivo: ' + err.message);
            setLoading(false);
        }
    };

    // Cálculo de estadísticas
    const calculateStats = (deviceData) => {
        // 1. Estado de conexión
        const networkStatusCounts = {
            Online: 0,
            Offline: 0
        };

        deviceData.forEach(device => {
            const status = device['Network Status'] || 'Unknown';
            networkStatusCounts[status] = (networkStatusCounts[status] || 0) + 1;
        });

        // 2. Análisis de tiempo desde la última conexión
        const currentDate = new Date();

        const connectionTimeCategories = {
            connected: 0,     // Dispositivos actualmente conectados
            today: 0,         // Conectados hoy
            thisWeek: 0,      // Conectados esta semana
            thisMonth: 0,     // Conectados este mes
            threeMonths: 0,   // Conectados en los últimos 3 meses
            older: 0,         // Más de 3 meses sin conectar
            never: 0          // Sin datos de conexión
        };

        // Fechas de referencia
        const today = new Date(currentDate);
        today.setHours(0, 0, 0, 0);

        const oneWeekAgo = new Date(currentDate);
        oneWeekAgo.setDate(currentDate.getDate() - 7);

        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(currentDate.getMonth() - 1);

        const threeMonthsAgo = new Date(currentDate);
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

        // Identificar dispositivos inactivos
        const inactive = [];

        deviceData.forEach(device => {
            const status = device['Network Status'];
            const checkingTime = device['Checking Time'];

            if (!checkingTime || checkingTime === '') {
                connectionTimeCategories.never++;
                inactive.push({ ...device, inactiveReason: 'Sin datos de conexión' });
                return;
            }

            const lastConnection = new Date(checkingTime);

            if (status === 'Online') {
                connectionTimeCategories.connected++;
            } else if (lastConnection >= today) {
                connectionTimeCategories.today++;
            } else if (lastConnection >= oneWeekAgo) {
                connectionTimeCategories.thisWeek++;
            } else if (lastConnection >= oneMonthAgo) {
                connectionTimeCategories.thisMonth++;
            } else if (lastConnection >= threeMonthsAgo) {
                connectionTimeCategories.threeMonths++;
            } else {
                connectionTimeCategories.older++;
                inactive.push({
                    ...device,
                    inactiveReason: 'Sin conectar por más de 3 meses',
                    daysSinceLastConnection: Math.floor((currentDate - lastConnection) / (1000 * 60 * 60 * 24))
                });
            }
        });

        // 3. Análisis de estado del disco duro
        const hddStatusStats = {};
        deviceData.forEach(device => {
            const status = device['HDD Status'] || 'Unknown';
            hddStatusStats[status] = (hddStatusStats[status] || 0) + 1;
        });

        // 4. Análisis de versión de software
        const versionStats = {};
        deviceData.forEach(device => {
            const version = device['Version'] || 'Unknown';
            versionStats[version] = (versionStats[version] || 0) + 1;
        });

        // Almacenar estadísticas
        setStatsData({
            networkStatusCounts,
            connectionTimeCategories,
            hddStatusStats,
            versionStats,
            totalDevices: deviceData.length
        });

        setInactiveDevices(inactive);
    };

    // Filtrar dispositivos para la tabla
    const filteredDevices = devices.filter(device => {
        // Filtro por término de búsqueda
        const matchesSearch = !searchTerm ||
            Object.values(device).some(value =>
                value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );

        // Filtro por estado
        const matchesStatus = filterStatus === 'all' ||
            (filterStatus === 'online' && device['Network Status'] === 'Online') ||
            (filterStatus === 'offline' && device['Network Status'] === 'Offline') ||
            (filterStatus === 'inactive' && (!device['Checking Time'] || new Date(device['Checking Time']) < new Date(new Date().setMonth(new Date().getMonth() - 3))));

        return matchesSearch && matchesStatus;
    });

    // Ordenar dispositivos
    const sortedDevices = [...filteredDevices].sort((a, b) => {
        const aValue = a[sortColumn] || '';
        const bValue = b[sortColumn] || '';

        if (sortColumn === 'Checking Time' || sortColumn === 'First Added Time') {
            const aDate = aValue ? new Date(aValue) : new Date(0);
            const bDate = bValue ? new Date(bValue) : new Date(0);

            return sortDirection === 'asc'
                ? aDate - bDate
                : bDate - aDate;
        }

        if (sortDirection === 'asc') {
            return aValue.toString().localeCompare(bValue.toString());
        } else {
            return bValue.toString().localeCompare(aValue.toString());
        }
    });

    // Manejar la ordenación de columnas
    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    // Componente para renderizar el dashboard
    const Dashboard = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {statsData && (
                <>
                    {/* Estado de conexión */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Estado de Conexión</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="bg-green-50 rounded-lg p-4 flex items-center">
                                <div className="bg-green-100 p-2 rounded-full mr-3">
                                    <Wifi className="text-green-600" size={24} />
                                </div>
                                <div>
                                    <p className="text-xs text-green-600">Online</p>
                                    <p className="text-xl font-bold">{statsData.networkStatusCounts.Online || 0}</p>
                                </div>
                            </div>
                            <div className="bg-red-50 rounded-lg p-4 flex items-center">
                                <div className="bg-red-100 p-2 rounded-full mr-3">
                                    <WifiOff className="text-red-600" size={24} />
                                </div>
                                <div>
                                    <p className="text-xs text-red-600">Offline</p>
                                    <p className="text-xl font-bold">{statsData.networkStatusCounts.Offline || 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Tiempo desde última conexión */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Tiempo desde última conexión</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="bg-blue-50 rounded-lg p-3">
                                <p className="text-xs text-blue-600">Hoy</p>
                                <p className="text-xl font-bold">{statsData.connectionTimeCategories.today}</p>
                            </div>
                            <div className="bg-blue-50 rounded-lg p-3">
                                <p className="text-xs text-blue-600">Esta semana</p>
                                <p className="text-xl font-bold">{statsData.connectionTimeCategories.thisWeek}</p>
                            </div>
                            <div className="bg-blue-50 rounded-lg p-3">
                                <p className="text-xs text-blue-600">Este mes</p>
                                <p className="text-xl font-bold">{statsData.connectionTimeCategories.thisMonth}</p>
                            </div>
                            <div className="bg-blue-50 rounded-lg p-3">
                                <p className="text-xs text-blue-600">Últimos 3 meses</p>
                                <p className="text-xl font-bold">{statsData.connectionTimeCategories.threeMonths}</p>
                            </div>
                        </div>
                    </div>

                    {/* Dispositivos inactivos */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Dispositivos inactivos</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="bg-yellow-50 rounded-lg p-4 flex items-center">
                                <div className="bg-yellow-100 p-2 rounded-full mr-3">
                                    <AlertCircle className="text-yellow-600" size={24} />
                                </div>
                                <div>
                                    <p className="text-xs text-yellow-600">Sin datos</p>
                                    <p className="text-xl font-bold">{statsData.connectionTimeCategories.never}</p>
                                </div>
                            </div>
                            <div className="bg-red-50 rounded-lg p-4 flex items-center">
                                <div className="bg-red-100 p-2 rounded-full mr-3">
                                    <Clock className="text-red-600" size={24} />
                                </div>
                                <div>
                                    <p className="text-xs text-red-600">+3 meses</p>
                                    <p className="text-xl font-bold">{statsData.connectionTimeCategories.older}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Estado del disco duro */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Estado de disco duro</h3>
                        <div className="space-y-3">
                            {Object.entries(statsData.hddStatusStats).map(([status, count]) => (
                                <div key={status} className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <HardDrive size={16} className="mr-2 text-gray-500" />
                                        <span>{status}</span>
                                    </div>
                                    <span className="font-semibold">{count}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Resumen */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Resumen general</h3>
                        <div className="space-y-4">
                            <div className="flex items-center justify-between">
                                <span className="text-gray-600">Total dispositivos:</span>
                                <span className="font-bold">{statsData.totalDevices}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="text-gray-600">Conectados ahora:</span>
                                <span className="font-bold text-green-600">{statsData.connectionTimeCategories.connected}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="text-gray-600">Requieren atención:</span>
                                <span className="font-bold text-red-600">
                                    {statsData.connectionTimeCategories.never + statsData.connectionTimeCategories.older}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Distribución por versión */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Versiones de software</h3>
                        <div className="space-y-3">
                            {Object.entries(statsData.versionStats).map(([version, count]) => (
                                <div key={version} className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <Activity size={16} className="mr-2 text-gray-500" />
                                        <span className="text-sm truncate max-w-xs">{version === 'Unknown' ? 'Sin datos' : version}</span>
                                    </div>
                                    <span className="font-semibold">{count}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );

    // Componente para renderizar la tabla de dispositivos
    const DevicesTable = () => (
        <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="flex flex-col md:flex-row justify-between p-4 border-b">
                <div className="relative mb-4 md:mb-0 w-full md:w-64">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                    <input
                        type="text"
                        placeholder="Buscar dispositivos..."
                        className="pl-10 pr-4 py-2 border rounded-lg w-full"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="flex space-x-2">
                    <select
                        className="border rounded-lg px-3 py-2"
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                    >
                        <option value="all">Todos los estados</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                        <option value="inactive">Inactivos (+3 meses)</option>
                    </select>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            {['Name', 'Network Status', 'Checking Time', 'HDD Status', 'Version'].map((column) => (
                                <th
                                    key={column}
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSort(column)}
                                >
                                    <div className="flex items-center">
                                        <span>{column}</span>
                                        {sortColumn === column && (
                                            sortDirection === 'asc' ?
                                                <ChevronUp size={16} className="ml-1" /> :
                                                <ChevronDown size={16} className="ml-1" />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {sortedDevices.length > 0 ? (
                            sortedDevices.map((device, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">{device.Name}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${device['Network Status'] === 'Online' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                            }`}>
                                            {device['Network Status']}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500">
                                            {device['Checking Time'] || 'Sin datos'}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500">{device['HDD Status']}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {device['Version'] || 'Sin datos'}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                    No se encontraron dispositivos con los filtros actuales
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="px-6 py-4 border-t">
                <p className="text-sm text-gray-500">
                    Mostrando {sortedDevices.length} de {devices.length} dispositivos
                </p>
            </div>
        </div>
    );

    // Componente para renderizar la lista de dispositivos inactivos
    const InactiveDevicesReport = () => (
        <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="p-6 border-b">
                <h3 className="text-lg font-semibold">Dispositivos inactivos (sin conexión por +3 meses)</h3>
                <p className="text-sm text-gray-500 mt-1">
                    Total: {inactiveDevices.length} dispositivos requieren atención
                </p>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dispositivo</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Última conexión</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Días inactivo</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Problema</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {inactiveDevices.length > 0 ? (
                            inactiveDevices.map((device, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className="ml-4">
                                                <div className="text-sm font-medium text-gray-900">{device.Name}</div>
                                                <div className="text-sm text-gray-500">{device['Serial No.']}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">{device['Checking Time'] || 'Nunca'}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {device.daysSinceLastConnection ? (
                                            <div className="text-sm text-gray-900">{device.daysSinceLastConnection}</div>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                Sin datos
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                            {device.inactiveReason}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                    No se encontraron dispositivos inactivos
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-100 p-4 md:p-8">
            <h1 className="text-2xl font-bold mb-6">Analizador de Dispositivos Móviles</h1>

            {/* Sección de carga de archivo */}
            {!fileData ? (
                <div className="bg-white rounded-lg shadow p-8 text-center max-w-md mx-auto">
                    <div className="flex justify-center mb-4">
                        <UploadCloud size={48} className="text-blue-500" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2">Carga tu archivo CSV</h2>
                    <p className="text-gray-500 mb-6">
                        Sube el archivo de dispositivos móviles para analizarlo
                    </p>
                    <label className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg cursor-pointer transition-colors">
                        Seleccionar archivo
                        <input
                            type="file"
                            accept=".csv"
                            className="hidden"
                            onChange={handleFileUpload}
                        />
                    </label>
                </div>
            ) : (
                <>
                    {/* Tabs de navegación */}
                    <div className="flex border-b mb-6">
                        <button
                            className={`py-2 px-4 font-medium ${activeTab === 'dashboard' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setActiveTab('dashboard')}
                        >
                            Dashboard
                        </button>
                        <button
                            className={`py-2 px-4 font-medium ${activeTab === 'devices' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setActiveTab('devices')}
                        >
                            Todos los dispositivos
                        </button>
                        <button
                            className={`py-2 px-4 font-medium ${activeTab === 'inactive' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setActiveTab('inactive')}
                        >
                            Dispositivos inactivos
                        </button>
                    </div>

                    {/* Contenido según el tab activo */}
                    {loading ? (
                        <div className="bg-white rounded-lg shadow p-8 text-center">
                            <p className="text-gray-500">Cargando datos...</p>
                        </div>
                    ) : error ? (
                        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
                            <div className="flex">
                                <AlertCircle className="text-red-500 mr-2" size={20} />
                                <p className="text-red-600">{error}</p>
                            </div>
                        </div>
                    ) : (
                        <>
                            {activeTab === 'dashboard' && <Dashboard />}
                            {activeTab === 'devices' && <DevicesTable />}
                            {activeTab === 'inactive' && <InactiveDevicesReport />}
                        </>
                    )}

                    {/* Botón para cargar nuevo archivo */}
                    <div className="mt-6">
                        <label className="text-blue-500 hover:text-blue-600 font-medium cursor-pointer transition-colors flex items-center">
                            <UploadCloud size={20} className="mr-2" />
                            Cargar otro archivo
                            <input
                                type="file"
                                accept=".csv"
                                className="hidden"
                                onChange={handleFileUpload}
                            />
                        </label>
                    </div>
                </>
            )}
        </div>
    );
};

export default MobileDeviceAnalyzer;