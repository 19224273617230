import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import {
  Typography,
  CardContent,
  Grid,
  CircularProgress,
  Box,
  Modal,
  IconButton,
  Avatar,
  Button,
  Card,
  Container,
  Chip,
  Paper,
  Divider,
  Alert,
  Tooltip,
  Fade,
  Skeleton,
  Breadcrumbs,
  Link,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Badge,
  Tab,
  Tabs,
  Stack,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CommentIcon from "@mui/icons-material/Comment";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FolderIcon from "@mui/icons-material/Folder";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Locker from "../Locker";
import DiagnosticoInspeccion from "./DiagnosticoInspeccion";
import InspectionPdfGenerator from "./InspectionPdfGenerator";

const InspectionDetails = () => {
  const { uid } = useParams();
  const [inspectionData, setInspectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [lockerOpen, setLockerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  const navigate = useNavigate();

  const fetchInspection = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, "inspecciones", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setInspectionData(docSnap.data());
        setError(null);
      } else {
        setError("No se encontró la inspección solicitada.");
      }
    } catch (err) {
      console.error("Error al recuperar los datos de la inspección:", err);
      setError(
        "Error al recuperar los datos de la inspección. Por favor, intente nuevamente."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInspection();
  }, [uid]);

  // Convierte el Timestamp a un formato de fecha legible
  const formatDate = (timestamp) => {
    if (!timestamp) return "Fecha no disponible";

    try {
      if (timestamp.toDate) {
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(date);
      }
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
    }

    return "Fecha no disponible";
  };

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleLockerSuccess = async () => {
    setLockerOpen(false);
    setConfirmDelete(true);
  };

  const confirmDeleteInspection = async () => {
    setLoading(true);

    try {
      const fotos = inspectionData.fotos || {};
      const deletePromises = Object.values(fotos).map((url) => {
        if (!url) return Promise.resolve();
        const imgRef = ref(storage, url);
        return deleteObject(imgRef).catch((err) => {
          console.warn("Error al eliminar imagen:", err);
          return Promise.resolve(); // Continue even if one image fails to delete
        });
      });

      await Promise.all(deletePromises);

      const docRef = doc(db, "inspecciones", uid);
      await deleteDoc(docRef);

      setSuccessMessage("Inspección eliminada correctamente");
      setShowSuccess(true);

      // Navigate after a short delay to show success message
      setTimeout(() => {
        navigate("/buscarinspeccion");
      }, 1500);
    } catch (err) {
      console.error("Error al eliminar la inspección:", err);
      setError("Error al eliminar la inspección.");
    } finally {
      setLoading(false);
      setConfirmDelete(false);
    }
  };

  const handleDeleteClick = () => {
    setLockerOpen(true);
  };

  const handleLockerClose = () => {
    setLockerOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  };

  const getStatusChip = (status) => {
    if (!status)
      return <Chip size="small" label="Sin estado" color="default" />;

    switch (status.toLowerCase()) {
      case "correcto":
      case "aprobado":
      case "aprobada":
      case "ok":
      case "completo":
      case "completa":
        return (
          <Chip
            size="small"
            icon={<CheckCircleIcon />}
            label="Correcto"
            color="success"
          />
        );
      case "pendiente":
      case "en progreso":
      case "en proceso":
        return (
          <Chip
            size="small"
            icon={<AccessTimeIcon />}
            label="Pendiente"
            color="warning"
          />
        );
      case "incorrecto":
      case "rechazado":
      case "rechazada":
      case "fallo":
      case "error":
        return (
          <Chip
            size="small"
            icon={<CancelIcon />}
            label="Incorrecto"
            color="error"
          />
        );
      default:
        return <Chip size="small" label={status} color="info" />;
    }
  };

  if (loading && !inspectionData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f5f7fa"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error && !inspectionData) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f5f7fa"
        px={3}
      >
        <Alert severity="error" sx={{ mb: 2, maxWidth: 500 }}>
          {error}
        </Alert>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Volver
        </Button>
      </Box>
    );
  }

  // Count photos (excluding Firma and Oficial)
  const photoCount = inspectionData?.fotos
    ? Object.keys(inspectionData.fotos).filter(
        (key) => key !== "Firma" && key !== "Oficial"
      ).length
    : 0;

  return (
    <Box
      sx={{
        bgcolor: "#f5f7fa",
        minHeight: "100vh",
        py: 4,
        px: { xs: 1, sm: 2, md: 3 },
      }}
    >
      <Container maxWidth="lg">
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, color: "#555" }}>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate("/")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inicio
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate("/buscarinspeccion")}
          >
            <AssignmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inspecciones
          </Link>
          <Typography
            color="text.primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <VerifiedUserIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {loading ? "Cargando..." : `Inspección #${uid.substring(0, 8)}...`}
          </Typography>
        </Breadcrumbs>

        {/* Botón de retorno y acciones */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          >
            Regresar
          </Button>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Editar inspección">
              <IconButton
                color="primary"
                onClick={() => navigate(`/nuevainspeccion/${uid}`)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar inspección">
              <IconButton color="error" onClick={handleDeleteClick}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Fade in={!loading || !!inspectionData}>
          <Box>
            <Card
              elevation={3}
              sx={{
                borderRadius: 2,
                overflow: "hidden",
                mb: 4,
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#1976d2",
                  color: "white",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "500" }}
                  >
                    <InfoIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                    Folio: {uid.substring(0, 8)}...
                  </Typography>
                  {inspectionData && getStatusChip(inspectionData.Status)}
                </Box>
              </Paper>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    label="Información"
                    icon={<InfoIcon />}
                    iconPosition="start"
                  />
                  <Tab
                    label={`Fotos (${photoCount})`}
                    icon={<PhotoLibraryIcon />}
                    iconPosition="start"
                  />
                </Tabs>
              </Box>

              <CardContent>
                {activeTab === 0 && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                      <Card
                        elevation={2}
                        sx={{
                          height: "100%",
                          borderRadius: 2,
                          overflow: "hidden",
                        }}
                      >
                        <CardContent sx={{ p: 0 }}>
                          <Box
                            sx={{
                              position: "relative",
                              backgroundColor: "#f0f8ff",
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <Tooltip title="Ampliar imagen">
                                  <IconButton
                                    size="small"
                                    sx={{
                                      bgcolor: "white",
                                      boxShadow: 2,
                                      "&:hover": { bgcolor: "#e0e0e0" },
                                    }}
                                    onClick={() => {
                                      if (inspectionData?.fotos?.["Oficial"]) {
                                        handleOpenModal(
                                          inspectionData.fotos["Oficial"]
                                        );
                                      }
                                    }}
                                    disabled={
                                      !inspectionData?.fotos?.["Oficial"]
                                    }
                                  >
                                    <ZoomInIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              }
                            >
                              <Avatar
                                src={
                                  inspectionData?.fotos?.["Oficial"] ||
                                  "/placeholder-profile.jpg"
                                }
                                alt="Oficial"
                                sx={{
                                  width: { xs: 120, sm: 150 },
                                  height: { xs: 120, sm: 150 },
                                  border: "3px solid white",
                                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                                }}
                              />
                            </Badge>

                            <Typography
                              variant="h6"
                              align="center"
                              gutterBottom
                              sx={{
                                mt: 2,
                                color: "#1976d2",
                                fontWeight: "bold",
                              }}
                            >
                              {inspectionData?.Nombre || "Nombre no disponible"}
                            </Typography>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              gutterBottom
                            >
                              Información del técnico
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                              }}
                            >
                              <EngineeringIcon
                                sx={{ color: "#1976d2", mr: 1, fontSize: 20 }}
                              />
                              <Typography variant="body2">
                                <strong>Técnico:</strong>{" "}
                                {inspectionData?.Usuario || "No disponible"}
                              </Typography>
                            </Box>
                          </Box>

                          {inspectionData?.fotos?.["Firma"] && (
                            <>
                              <Divider />
                              <Box
                                sx={{
                                  p: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Firma del técnico
                                </Typography>
                                <Paper
                                  elevation={1}
                                  sx={{
                                    p: 1,
                                    backgroundColor: "#ffffff",
                                    borderRadius: 1,
                                    width: "100%",
                                    mt: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleOpenModal(
                                      inspectionData.fotos["Firma"]
                                    )
                                  }
                                >
                                  <img
                                    src={inspectionData.fotos["Firma"]}
                                    alt="Firma"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100px",
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </Paper>
                              </Box>
                            </>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Card
                        elevation={2}
                        sx={{
                          borderRadius: 2,
                          mb: 3,
                          height: "100%",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              color: "#1976d2",
                              fontWeight: "500",
                              pb: 1,
                              borderBottom: "1px solid #e0e0e0",
                              mb: 2,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AssignmentIcon sx={{ mr: 1 }} />
                            Detalles de la Inspección
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Paper
                                elevation={0}
                                sx={{
                                  p: 2,
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: 2,
                                  mb: 2,
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <CalendarTodayIcon
                                    sx={{
                                      color: "#1976d2",
                                      mr: 1.5,
                                      fontSize: 20,
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      Fecha y hora
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: "medium" }}
                                    >
                                      {formatDate(inspectionData?.Date)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Paper
                                elevation={0}
                                sx={{
                                  p: 2,
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: 2,
                                  mb: 2,
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <ElectricCarIcon
                                    sx={{
                                      color: "#1976d2",
                                      mr: 1.5,
                                      fontSize: 20,
                                    }}
                                  />
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      Unidad
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: "medium" }}
                                    >
                                      {inspectionData?.Unidad ||
                                        "No especificada"}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                          </Grid>

                          <Divider sx={{ my: 2 }} />

                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "500",
                              mb: 2,
                            }}
                          >
                            Diagnóstico
                          </Typography>

                          <DiagnosticoInspeccion
                            estadoGeneral={inspectionData?.EstadoGeneral}
                            fusiblesReemplazados={
                              inspectionData?.FusiblesReemplazados
                            }
                          />

                          <Divider sx={{ my: 2 }} />

                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "500",
                              mb: 2,
                            }}
                          >
                            Comentarios
                          </Typography>

                          <Paper
                            elevation={0}
                            sx={{
                              p: 2,
                              backgroundColor: "#f5f5f5",
                              borderRadius: 2,
                              borderLeft: "4px solid #1976d2",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <CommentIcon
                                sx={{
                                  color: "#1976d2",
                                  mr: 1.5,
                                  fontSize: 20,
                                  alignSelf: "flex-start",
                                  mt: 0.5,
                                }}
                              />
                              <Typography variant="body1">
                                {inspectionData?.Comentario ||
                                  "Sin comentarios"}
                              </Typography>
                            </Box>
                          </Paper>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}

                {activeTab === 1 && (
                  <Box>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PhotoLibraryIcon sx={{ mr: 1 }} />
                      Galería de fotos
                    </Typography>

                    {photoCount === 0 ? (
                      <Alert severity="info" sx={{ mb: 2 }}>
                        No hay fotos disponibles para esta inspección.
                      </Alert>
                    ) : (
                      <Grid container spacing={2}>
                        {inspectionData?.fotos &&
                          Object.entries(inspectionData.fotos)
                            .filter(
                              ([key]) => key !== "Firma" && key !== "Oficial"
                            )
                            .map(([key, url]) => (
                              <Grid item xs={12} sm={6} md={4} key={key}>
                                <Card
                                  elevation={2}
                                  sx={{
                                    borderRadius: 2,
                                    cursor: "pointer",
                                    transition:
                                      "transform 0.2s, box-shadow 0.2s",
                                    "&:hover": {
                                      transform: "translateY(-4px)",
                                      boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                                    },
                                  }}
                                  onClick={() => handleOpenModal(url)}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      paddingTop: "75%", // 4:3 aspect ratio
                                      overflow: "hidden",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                  >
                                    <img
                                      src={url}
                                      alt={key}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        backgroundColor: "rgba(0,0,0,0.6)",
                                        color: "white",
                                        p: 1,
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "medium" }}
                                      >
                                        {key}
                                      </Typography>
                                    </Box>
                                    <Tooltip title="Ver imagen">
                                      <IconButton
                                        sx={{
                                          position: "absolute",
                                          top: 8,
                                          right: 8,
                                          backgroundColor:
                                            "rgba(255,255,255,0.8)",
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(255,255,255,1)",
                                          },
                                        }}
                                      >
                                        <ZoomInIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Card>
                              </Grid>
                            ))}
                      </Grid>
                    )}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Container>

      {/* Modal para visualización de imágenes */}
      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "95%", sm: "90%", md: "80%" },
              maxWidth: "1200px",
              maxHeight: "90vh",
              bgcolor: "#fff",
              borderRadius: 2,
              boxShadow: 24,
              p: { xs: 2, sm: 3 },
              outline: "none",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ color: "#1976d2" }}
              >
                Vista previa de imagen
              </Typography>
              <IconButton
                onClick={handleCloseModal}
                size="small"
                sx={{ color: "#666" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                borderRadius: 1,
              }}
            >
              <img
                src={selectedImage}
                alt="Vista previa"
                style={{
                  maxWidth: "100%",
                  maxHeight: "70vh",
                  objectFit: "contain",
                }}
              />
            </Box>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={() => {
                  window.open(selectedImage, "_blank");
                }}
              >
                Descargar
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>

      {/* Locker y diálogos de confirmación */}
      <Locker
        uid={uid}
        open={lockerOpen}
        onClose={handleLockerClose}
        onSuccess={handleLockerSuccess}
      />

      <Dialog
        open={confirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de que desea eliminar permanentemente esta inspección?
            Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={confirmDeleteInspection}
            color="error"
            variant="contained"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccess}
        autoHideDuration={4000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <InspectionPdfGenerator inspectionData={{ ...inspectionData, id: uid }} />
    </Box>
  );
};

export default InspectionDetails;
