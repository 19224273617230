import React, { useState, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import NuevoOficial from "../../AdminDashboard/Oficiales/NuevoOficial";
import {
  ArrowLeft,
  Save,
  UserPlus,
  Pencil,
  X,
  CheckCircle,
  RotateCcw,
  Undo,
  Clock,
  User,
  Eye,
  InfoIcon,
} from "lucide-react";

const Step4OficialFirma = ({
  oficiales,
  selectedOficial,
  setSelectedOficial,
  firma,
  handleFirmaPad,
  handleBack,
  handleSubmit,
  uploading,
  inspectionId,
  isEditing = false,
}) => {
  const [showNuevoOficial, setShowNuevoOficial] = useState(false);
  const [oficialesList, setOficialesList] = useState(oficiales);
  const [searchTerm, setSearchTerm] = useState("");
  const [firmaCompleted, setFirmaCompleted] = useState(!!firma);
  const [signaturePadScale, setSignaturePadScale] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const [touchSupport, setTouchSupport] = useState(false);
  const signaturePadRef = React.useRef(null);
  const containerRef = React.useRef(null);

  // Detect touch support on mount
  useEffect(() => {
    setTouchSupport("ontouchstart" in window || navigator.maxTouchPoints > 0);
  }, []);

  // Ajustar tamaño del pad de firma basado en el tamaño de la pantalla
  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.offsetWidth;
        setContainerWidth(newWidth);

        // Calcular escala basada en el ancho disponible
        // Para móviles, usamos un enfoque diferente para maximizar el área de firma
        const isMobile = window.innerWidth < 640;
        const baseWidth = isMobile ? 300 : 500;
        const paddingOffset = isMobile ? 16 : 32;

        const newScale = Math.min(1, (newWidth - paddingOffset) / baseWidth);
        setSignaturePadScale(newScale);
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Si ya hay una firma guardada, marcarla como completada
  useEffect(() => {
    setFirmaCompleted(!!firma);
  }, [firma]);

  // Actualiza la lista de oficiales cuando cambian los props
  useEffect(() => {
    setOficialesList(oficiales);
  }, [oficiales]);

  // Filtrar oficiales basado en el término de búsqueda
  const filteredOficiales = searchTerm
    ? oficialesList.filter((oficial) =>
        oficial.nombre.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : oficialesList;

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      handleFirmaPad(null);
      setFirmaCompleted(false);
    }
  };

  const handleSignatureEnd = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      handleFirmaPad(signaturePadRef.current);
      setFirmaCompleted(true);
    } else {
      setFirmaCompleted(false);
    }
  };

  const handleOpenNuevoOficial = () => {
    setShowNuevoOficial(true);
  };

  const handleCloseNuevoOficial = () => {
    setShowNuevoOficial(false);
  };

  const handleAddOfficial = (newOfficial) => {
    setOficialesList([...oficialesList, newOfficial]);
    setSelectedOficial(newOfficial.nombre);
    handleCloseNuevoOficial();
  };

  const handleFinalSubmit = async () => {
    const endTime = new Date().toLocaleString();
    await handleSubmit(endTime);
  };

  // Para cargar una firma existente en el SignaturePad
  useEffect(() => {
    if (firma && signaturePadRef.current) {
      // Limpiar primero
      signaturePadRef.current.clear();

      // Cargar la imagen como fondo
      const img = new Image();
      img.onload = () => {
        const canvas = signaturePadRef.current._canvas;
        const ctx = canvas.getContext("2d");

        // Dibujar la imagen en el canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        setFirmaCompleted(true);
      };
      img.src = firma;
    }
  }, [firma, signaturePadScale]);

  return (
    <div className="w-full">
      {/* Sección de selección de oficial */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center mb-4">
          <User className="mr-2 h-5 w-5 text-indigo-600" />
          Oficial Responsable
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Seleccione un oficial
            </label>

            {/* Buscador rápido de oficiales */}
            <div className="relative mb-2">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Buscar oficial..."
                className="w-full p-3 pl-10 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              {searchTerm && (
                <button
                  onClick={() => setSearchTerm("")}
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>

            <div className="relative">
              <select
                value={selectedOficial}
                onChange={(e) => setSelectedOficial(e.target.value)}
                className="w-full p-3 pr-10 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white appearance-none"
              >
                <option value="">Seleccionar oficial...</option>
                {filteredOficiales.map((oficial) => (
                  <option key={oficial.id} value={oficial.nombre}>
                    {oficial.nombre}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 20 20"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  />
                </svg>
              </div>
            </div>

            {/* Indicador de resultados de búsqueda */}
            {searchTerm && (
              <div className="mt-1 text-xs text-indigo-600">
                {filteredOficiales.length}{" "}
                {filteredOficiales.length === 1 ? "resultado" : "resultados"}{" "}
                para "{searchTerm}"
              </div>
            )}

            <div className="flex mt-3 space-x-2">
              <button
                type="button"
                onClick={handleOpenNuevoOficial}
                className="inline-flex items-center px-4 py-2 border border-indigo-300 text-sm font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-100 transition-colors"
              >
                <UserPlus className="mr-2 h-4 w-4" />
                Agregar Nuevo Oficial
              </button>

              {selectedOficial && (
                <button
                  type="button"
                  onClick={() => setSelectedOficial("")}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 transition-colors"
                >
                  <X className="mr-1 h-4 w-4" />
                  Limpiar
                </button>
              )}
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <div className="flex items-center mb-3">
              <Clock className="text-indigo-600 mr-2 h-4 w-4" />
              <span className="text-sm font-medium text-gray-700">
                Estado de la inspección
              </span>
            </div>

            <ul className="space-y-2">
              <li className="flex items-center">
                <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 text-green-600 mr-2">
                  <CheckCircle className="h-4 w-4" />
                </div>
                <span className="text-sm text-gray-600">
                  Unidad seleccionada
                </span>
              </li>
              <li className="flex items-center">
                <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 text-green-600 mr-2">
                  <CheckCircle className="h-4 w-4" />
                </div>
                <span className="text-sm text-gray-600">Fotos subidas</span>
              </li>
              <li className="flex items-center">
                <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 text-green-600 mr-2">
                  <CheckCircle className="h-4 w-4" />
                </div>
                <span className="text-sm text-gray-600">Datos guardados</span>
              </li>
              <li className="flex items-center">
                <div
                  className={`flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full ${
                    selectedOficial
                      ? "bg-green-100 text-green-600"
                      : "bg-gray-100 text-gray-400"
                  } mr-2`}
                >
                  {selectedOficial ? (
                    <CheckCircle className="h-4 w-4" />
                  ) : (
                    <span className="text-xs">4</span>
                  )}
                </div>
                <span
                  className={`text-sm ${
                    selectedOficial ? "text-gray-600" : "text-gray-400"
                  }`}
                >
                  Oficial asignado
                </span>
              </li>
              <li className="flex items-center">
                <div
                  className={`flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full ${
                    firmaCompleted
                      ? "bg-green-100 text-green-600"
                      : "bg-gray-100 text-gray-400"
                  } mr-2`}
                >
                  {firmaCompleted ? (
                    <CheckCircle className="h-4 w-4" />
                  ) : (
                    <span className="text-xs">5</span>
                  )}
                </div>
                <span
                  className={`text-sm ${
                    firmaCompleted ? "text-gray-600" : "text-gray-400"
                  }`}
                >
                  Firma completada
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Sección de firma - Optimizada para móviles */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center mb-4">
          <Pencil className="mr-2 h-5 w-5 text-indigo-600" />
          Firma del Oficial
        </h2>

        <div
          className="bg-white rounded-lg border border-gray-300 p-2 sm:p-4 mb-6"
          ref={containerRef}
        >
          <div className="flex flex-col items-center">
            {/* Instrucciones para firma - Más visible en móviles */}
            {!firma && !firmaCompleted && (
              <div className="mb-4 p-3 bg-blue-50 text-blue-700 rounded-md text-sm border border-blue-200 w-full">
                <p className="flex items-center">
                  <svg
                    className="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {touchSupport
                    ? "Firme con su dedo dentro del área designada. Gire su dispositivo horizontalmente para mayor comodidad."
                    : "Firme dentro del recuadro usando el ratón."}
                </p>
              </div>
            )}

            {/* Área de firma con bordes más visibles */}
            <div
              className={`border-2 border-indigo-300 rounded-lg overflow-hidden bg-white shadow-sm ${
                touchSupport ? "touch-manipulation" : ""
              }`}
              style={{
                transform: `scale(${signaturePadScale})`,
                transformOrigin: "top center",
                width: window.innerWidth < 640 ? 300 : 500,
                height: window.innerWidth < 640 ? 150 : 200,
                maxWidth: "100%",
              }}
            >
              <SignaturePad
                ref={signaturePadRef}
                penColor="black"
                backgroundColor="white"
                canvasProps={{
                  width: window.innerWidth < 640 ? 300 : 500,
                  height: window.innerWidth < 640 ? 150 : 200,
                  style: {
                    backgroundColor: "white",
                    width: "100%",
                    height: "100%",
                  },
                  className: touchSupport ? "touch-none" : "",
                }}
                onEnd={handleSignatureEnd}
              />
            </div>

            {/* Botones de control - Reorganizados para mejor acceso en móvil */}
            <div className="flex flex-wrap justify-center gap-2 mt-4 w-full">
              <button
                type="button"
                onClick={clearSignature}
                className="flex-1 sm:flex-none inline-flex items-center justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
              >
                <RotateCcw className="mr-1 h-4 w-4" />
                Limpiar Firma
              </button>

              {firma && (
                <button
                  type="button"
                  className="flex-1 sm:flex-none inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
                  onClick={() => {
                    // Abrir la firma en una nueva ventana para mejor visualización
                    const win = window.open("", "_blank");
                    win.document.write(
                      `<img src="${firma}" style="max-width:100%" />`
                    );
                  }}
                >
                  <Eye className="mr-1 h-4 w-4" />
                  Ver Firma
                </button>
              )}
            </div>

            {/* Mensaje de estado de firma */}
            {firmaCompleted && (
              <div className="mt-4 p-3 bg-green-50 text-green-700 rounded-md text-sm text-center flex items-center justify-center w-full">
                <CheckCircle className="mr-2 h-4 w-4" />
                Firma registrada correctamente
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Botones de navegación - Adaptados para móvil */}
      <div className="flex flex-col sm:flex-row justify-between gap-3 mt-8">
        <button
          type="button"
          onClick={handleBack}
          className="px-5 py-3 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 flex items-center justify-center transition-colors"
        >
          <ArrowLeft className="mr-1 h-4 w-4" />
          Anterior
        </button>

        <button
          type="button"
          onClick={handleFinalSubmit}
          disabled={uploading || !selectedOficial || !firmaCompleted}
          className={`px-5 py-3 rounded-md flex items-center justify-center ${
            uploading || !selectedOficial || !firmaCompleted
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-indigo-600 text-white hover:bg-indigo-700"
          } transition-colors`}
        >
          {uploading ? (
            <>
              <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
              Guardando...
            </>
          ) : (
            <>
              <Save className="mr-1 h-4 w-4" />
              {isEditing ? "Actualizar Inspección" : "Crear Inspección"}
            </>
          )}
        </button>
      </div>

      {/* Si el botón está deshabilitado, mostrar mensaje de ayuda */}
      {(!selectedOficial || !firmaCompleted) && !uploading && (
        <div className="mt-4 p-3 bg-amber-50 border border-amber-200 rounded-md">
          <p className="text-amber-700 text-sm flex items-start">
            <svg
              className="h-5 w-5 mr-1 text-amber-500 flex-shrink-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {!selectedOficial && !firmaCompleted
              ? "Debe seleccionar un oficial y completar la firma para continuar."
              : !selectedOficial
              ? "Debe seleccionar un oficial para continuar."
              : "Debe completar la firma para continuar."}
          </p>
        </div>
      )}

      {/* Modal para agregar nuevo oficial */}
      <NuevoOficial
        open={showNuevoOficial}
        handleClose={handleCloseNuevoOficial}
        onOfficialAdded={handleAddOfficial}
      />
    </div>
  );
};

export default Step4OficialFirma;
